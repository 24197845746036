<!-- SearchForm.vue -->
<template>
	<div>
		<form @submit.prevent="search" class="bl_form">
			<input v-model="item" type="text" id="item" placeholder="Enter item" class="bl_form_input" />
			<button type="submit" class="bl_form_btn">Search</button>
		</form>
	</div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      item: "",
      searchResults: [],
    };
  },
  methods: {
    search() {
      const timestamp = new Date().getTime();
      axios
        .get(`./assets/data/book.json?t=${timestamp}`) // クエリパラメータにタイムスタンプを追加
        .then((response) => {
          const books = response.data;
          const matchedBooks = [];
          const searchItem = this.item.toLowerCase();
          books.forEach((book) => {
            if (
              book.isbn.toLowerCase().includes(searchItem) ||
              book.author.toLowerCase().includes(searchItem) ||
              book.publisher.toLowerCase().includes(searchItem) ||
              book.title.toLowerCase().includes(searchItem)
            ) {
              matchedBooks.push(book);
            }
          });

          if (this.item === "") {
            this.$emit("search-results", response.data, this.item); // 検索ワードを親コンポーネントに渡す
          } else if (matchedBooks.length === 0) {
            this.$emit("search-results", "NoItem", this.item); // 検索ワードを親コンポーネントに渡す
          } else {
            this.searchResults = matchedBooks;
            this.$emit("search-results", this.searchResults, this.item); // 検索ワードを親コンポーネントに渡す
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

</script>
