<!-- Home.vue -->
<template>
	<SearchForm @search-results="updateResults" />
	<h2 class="bl_form_result">{{ SearchItem }}</h2>
	<ul class="bl_list">
		<li v-for="(item, index) in sortedResults" :key="index" class="bl_list_item">
			<figure class="bl_list_img">
				<img :src="item.image" alt="" />
			</figure>
			<div class="bl_list_box">
				<h5 class="bl_list_ttl">
					{{ item.title }}
				</h5>
				<p class="bl_list_author">
					{{ item.author }}
				</p>
				<p class="bl_list_pub">
					{{ item.publisher }}
				</p>
				<p class="bl_list_isbn">
					{{ item.isbn }}
				</p>
			</div>
		</li>
	</ul>
	{{ NoItem }}
</template>

<script>
import SearchForm from "@/components/SearchForm.vue";

export default {
	name: "Home",
	components: {
		SearchForm,
	},
	data() {
		return {
			searchResults: [],
			noResult: false,
			SearchItem: "",
		};
	},
	methods: {
		updateResults(results, searchWord) {
			this.SearchItem = searchWord;
			if (results === "NoItem") {
				this.searchResults = [];
				this.noResult = true;
				this.NoItem = "NoItem";
			} else if (results.length === 0) {
				this.searchResults = [];
				this.noResult = true;
			} else {
				console.log(results);
				this.searchResults = results;
				this.noResult = false;
			}
		},
	},
	computed: {
		sortedResults() {
			return this.searchResults.slice().sort((a, b) => {
				if (a.author !== b.author) {
					return a.author.localeCompare(b.author);
				}
				return a.title.localeCompare(b.title);
			});
		},
	},
};
</script>

<style lang="scss">
$black: #252525;
:root {
	font-size: 62.5%;
}
#app {
	color: $black;
	font-size: 1.6rem;
	width: 92%;
	margin-left: auto;
	margin-right: auto;
}
.bl_list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
	gap: 10px;
	&_item {
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 10px;
		padding: 10px;
		border: 1px solid rgba($black, 0.4);
		border-radius: 5px;
	}
	&_img {
		img {
			width: 100%;
			height: auto;
		}
	}
	&_ttl {
		font-weight: bold;
		margin-bottom: 1em;
	}
	&_author {
		font-size: 1.4rem;
		font-weight: bold;
		margin-bottom: 1em;
	}
	&_pub {
		font-size: 1.4rem;
		margin-bottom: 1em;
	}
	&_isbn {
		font-size: 1.2rem;
	}
}
.bl_form {
	margin-bottom: 20px;
	padding: 10px;
	border: 1px solid;
	display: grid;
	grid-template-columns: 2fr 1fr;
	gap: 10px;
	&_input {
		padding: 0.5em;
		border: 1px solid $black;
	}
	&_btn {
		background-color: $black;
		padding: 1em 0;
		text-align: center;
		display: block;
		color: #fff;
	}
	&_result {
		font-size: 2.4rem;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
}
</style>
