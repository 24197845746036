<template>
  <main>
    <router-link to="/">Home</router-link>
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  name: 'App'
}
</script>