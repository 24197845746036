import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About_page from '../views/About_page.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/about',
    name: 'About_page',
    component: About_page
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router